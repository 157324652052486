import { useLocation, useNavigate } from '@remix-run/react';

import { useLiveCallback } from '../../../hooks/useLiveCallback';

type Options = {
  replace?: boolean;
  query?: {
    [key: string]: string;
  };
};

export function useOpenTrainingEditor(): (id: string, query?: Options) => void {
  const navigate = useNavigate();
  const location = useLocation();
  return useLiveCallback((id: string, options?: Options) => {
    const from = `${location.pathname}${location.search}`;
    const params = new URLSearchParams();
    if (options?.query) {
      for (const [key, value] of Object.entries(options.query)) {
        params.set(key, value);
      }
    }
    navigate(
      {
        pathname: `/trainings/${id}/edit`,
        search: params.size > 0 ? `?${params.toString()}` : undefined,
      },
      {
        state: { from },
        replace: options?.replace,
      }
    );
  });
}
